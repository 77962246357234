<div class="relative !mb-2 !border border-palette-gray-200 !rounded-lg"
    [ngClass]="{
            'border border-palette-orange-dark-500 rounded-lg': item.best_match && currentPage === 1 && numOfPropertiesAPI2 !== 0 }">
    <a 
        [routerLink]="genRouterLink(item.property_id, item.property_name, item.city_name)"
        [queryParams]="genQueryParams(item.property_id, item.property_name, item.city_name)"
        class="absolute top-0 right-0 left-0 bottom-0 z-[4]"   
    >
    </a>
     <div   [ngClass]="{ 'border border-palette-gray-200' : isLogin}"
            *ngIf="item.best_match && currentPage === 1 && numOfPropertiesAPI2 !== 0"
            class="!h-[26px] !px-2 !py-1 bg-gradient-to-br border border-palette-orange-dark-500 from-palette-orange-dark-400 to-palette-orange-dark-500 absolute z-[6] top-0 left-0 rounded-tl-md rounded-br-md">
            <p class="!h-[18px] font-inter !text-xs !font-medium !leading-[18px] text-palette-base-white">{{ 'DETAIL.BEST_MATCH' | translate }}</p>
        </div>
    <div
        [ngClass]="{
            'best-match': item.best_match && currentPage === 1 && numOfPropertiesAPI2 !== 0,
            'md:!h-[276px]': displayPolicy.length > 0,
            'md:!h-[230px]': (!(displayPolicy.length > 0) && isPrice),
            'md:!h-[229px]': (displayPolicy.length === 0),
        }"
        class="max-md:!p-2 md:!p-4 md:flex bg-white relative shadow-sm bg-opacity-5 {{ !isLogin ? 'rounded-t-lg' : 'rounded-lg' }}">
        <div 
            class="relative z-[5] max-md:!w-full max-md:!h-[148px] md:w-1/3 md:max-w-[33.33%] md:min-w-[33.33%]"
            [ngClass]="{
                'md:!h-[15.25rem]': displayPolicy.length > 0,
                'md:!h-[196px]': (!(displayPolicy.length > 0) && isPrice),
            }"
        >
            <img *ngIf="item.property_image_overview_id" class="!h-full min-w-full !max-w-full border rounded-lg !object-cover cursor-pointer bg-palette-gray-200" 
                src="{{baseImageId}}/{{item.property_image_overview_id}}"
                (error)="replaceWithDefaultImage($event)"
                (click)="quickViewPhotos($event, checkImageList(item.property_image_urls, item.property_image_ids))"
            >
            <img *ngIf="!item.property_image_overview_id" class="!h-full min-w-full !max-w-full border rounded-lg !object-cover cursor-pointer bg-palette-gray-200"
                src="{{ item.property_image_overview_url}}" (error)="replaceWithDefaultImage($event)"
                (click)="quickViewPhotos($event, checkImageList(item.property_image_urls, item.property_image_ids))">
            <button 
                (click)="handleBookmark($event)"
                class="max-md:!w-7 max-md:!h-7 hover:cursor-pointer flex items-center max-md:!p-1.5 max-md:rounded-md md:!w-9 md:!h-9 md:!p-2 md:rounded-lg absolute top-2 right-2 bg-[#10182866]"
            >
                <i
                    class="max-md:!text-base max-md:!leading-4 !text-xl !leading-5"
                    [ngClass]="{ 'sctr-icon-solid-bookmark text-palette-amber-400': isBookmarked, 'text-white sctr-icon-bookmark': !isBookmarked }">
                </i>
            </button>
        </div>
        <div
            [ngClass]="{'content-best_match': item.best_match && currentPage === 1 && numOfPropertiesAPI2 !== 0}"
            class="lg:relative max-md:!mt-2 md:!w-2/3 md:max-w-[calc(66.66%-16px)] md:min-w-[calc(66.66%-16px)] md:!ml-4 md:!h-[244px]"
        >
            <div class="flex">
                <div class="max-md:!w-[80%] max-md:!h-9 2xl:!w-[65%] xl:max-2xl:!w-[50%] md:max-xl:!w-[50%] md:!h-12">
                    <div (mouseenter)="showStarTooltip = true" (mouseleave)="showStarTooltip = false" class="relative">
                        <app-star [count]="item.star" starStyle="!gap-0.5"
                            iconStyle="sctr-icon-solid-star-01 text-palette-amber-400 text-xl"
                            >
                        </app-star>
                    </div>
                    <div>
                    <a  [routerLink]="genRouterLink(item.property_id, item.property_name, item.city_name)"
                        [queryParams]="genQueryParams(item.property_id, item.property_name, item.city_name)"
                        class="" 
                        >
                        <diV class="relative">
                            <p 
                                class="max-md:!text-sm md:!text-lg font-inter relative z-[5]  !font-semibold text-palette-gray-900 truncate"
                                [tooltip]="item.property_name"
                                [isTruncated]="true"
                                [theme]="TooltipTheme.DARK_LIGHT"
                                [position]="TooltipPosition.ABOVE"
                            >
                                {{ item.property_name }}
                            </p>
                        </diV>
                    </a>
                    </div>
                    
                </div>
                <div class="max-md:!w-[20%] 2xl:!w-[35%] xl:max-2xl:!w-[50%] md:max-xl:!w-[50%] md:!ml-2 relative">
                    <div class="max-md:relative md:!absolute md:!top-0 md:!right-0 flex justify-end items-center">
                        <div *ngIf="item.average_rating_point">
                            <div *ngIf="!isVote" class="w-full ">
                                <p class="md:!text-base font-inter !font-medium text-right text-palette-gray-700">{{item.average_rating_text}}</p>
                                <p class="md:!text-sm font-inter !font-normal text-palette-gray-500">{{ item.num_of_ratings }} reviews</p>
                            </div>
                            <app-rating-score [rating]="item.average_rating_point" 
                                [style]="'max-md:absolute max-md:top-0 max-md:right-0 md:!ml-4 relative max-md:!px-2 max-md:!py-1 max-md:!text-xs max-md:!leading-[18px] md:!px-3 md:!py-1 md:!text-base md:!h-8 border border-palette-blue-600 text-palette-blue-600'" 
                                [tooltipStyle]="'bottom-1/2 right-[120%] translate-y-[50%] w-[150px] min-[370px]:w-[180px]'">
                            </app-rating-score>
                        </div>
                        <div class="flex justify-center" *ngIf="!item.average_rating_point">
                            <p class="font-inter !text-sm !font-medium text-palette-gray-500 whitespace-nowrap">{{'COMMON.NEW_ON_SOCTRIP' | translate}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="max-md:!h-[auto] md:!mt-3 md:!mb-2 flex justify-between">
                <div class="max-md:!w-[90%] md:!w-[80%] max-md:mt-2.5 flex relative flex-col">
                    <div *ngIf="item.address" class="relative z-[5] max-md:!w-[80%] md:!w-auto max-md:!py-2 flex align-middle">
                        <i class="sctr-icon-marker-pin-01 max-md:!text-base !text-lg !leading-[18px] text-palette-gray-500"></i>
                        <div  
                            (click)="viewMap(item)"
                            class="hover:underline max-md:!leading-6 max-md:!text-xs md:!text-sm font-inter !font-medium text-palette-gray-500 !ml-1 truncate cursor-pointer"
                            [tooltip]="item.address"
                            [isTruncated]="true"
                            [theme]="TooltipTheme.DARK_LIGHT"
                            [position]="TooltipPosition.ABOVE"
                        >
                            {{ item.address }}</div>
                    </div>
                    <div class="flex align-middle">
                        <p *ngIf="item.distance_from_center"
                            class="max-md:!text-xs md:!text-sm font-inter !font-medium text-palette-gray-500 w-[18px] text-center">
                            &nbsp;•&nbsp;</p>
                        <div *ngIf="item.distance_from_center" #hotelDistance (mouseover)="mouseOverHotelDistance()"
                            class="max-md:!text-xs md:!text-sm font-inter !font-medium text-palette-gray-500 !ml-1 truncate">
                            {{ item.distance_from_center | number:'1.2-2' }} {{ 'DETAIL.KM_FROM_CENTRE' | translate }}</div>
                        <div
                            class="absolute inline-block !px-3 !py-2 text-xs font-semibold text-center transition-opacity duration-200 ease-in bg-white rounded-lg tooltip-hotel-distance z-100 text-palette-gray-700 whitespace-nowrap">
                            {{ item.distance_from_center | number:'1.2-2' }} {{ 'DETAIL.KM_FROM_CENTRE' | translate }}</div>
                    </div>
                </div>
                <div *ngIf="isVote" class="max-md:!w-[20%]">
                    <p class="max-md:!text-xs max-md:!leading-[18px] font-inter !font-medium text-right text-palette-gray-700">
                        {{ item.average_rating_point }}
                    </p>
                    <p class="max-md:!text-[10px] max-md:!leading-[16px] font-inter !font-normal text-palette-gray-500 text-right">
                        {{ item.num_of_ratings }} reviews
                    </p>    
                </div>
            </div>
            <div *ngIf="item.room_amenity_list.length > 0" class="max-md:flex max-md:!gap-2 max-md:!mt-1 md:!hidden">
                <ng-container  *ngFor="let facility of item.room_amenity_list.slice(0,5)">
                    <div class="!h-4 !w-4">
                        <i class="text-palette-blue-600 text-xl" [ngClass]="'sctr-icon-' + facility.amenity_icon"></i>
                    </div>
                </ng-container>  
            </div>
            <div class="max-md:!mt-3 max-md:relative max-md:!h-6 md:flex md:justify-between price-hidden">
                <div class="max-md:!hidden">
                    <p class="md:!text-base font-inter !font-medium text-palette-gray-700">{{ item.room_type_name }}</p>
                </div>
                <!-- <span *ngIf="isLoadingPriceCache; then loading; else showPriceCache"></span> -->
                <div *ngIf="isPrice && !isLoadingAPI" class="max-md:absolute max-md:right-0">
                    <div *ngIf="item.origin_price" class="max-md:!text-sm max-md:!ml-0.5 md:!text-base md:!ml-0.5 line-through font-inter !font-normal !text-palette-gray-500">
                        <app-price-format [price]="item.origin_price"></app-price-format>
                    </div>
                </div> 
            </div>
            <div class="relative lg:absolute w-full">
                <div #container class="max-md:!relative max-md:!h-12 md:!h-[52px] md:flex md:!mt-2">
                    <div #tag class="max-md:!hidden md:!w-[62.91%] md:flex md:flex-wrap overflow-hidden">
                        <div>
                            <div #tagLine1 *ngIf="displayFacility1.length > 0" class="w-full flex flex-wrap">
                                <app-tag class="md:!mb-2" *ngFor="let facility of displayFacility1" type="normal" [item]="facility.amenity_name"></app-tag>
                            </div>
                            <div #tagLine2 *ngIf="displayFacility2.length > 0" class="relative w-full flex flex-wrap">
                                <app-tag class="md:!mb-2" *ngFor="let facility of displayFacility2" type="normal" [item]="facility.amenity_name"></app-tag>
                                <span 
                                    (mouseenter)="onMouseTooltipFacility($event)"
                                    (mouseleave)="onMouseLeaveTooltipFacility()"
                                    *ngIf="hasRemainingFacility"
                                    class="md:!h-[22px] md:!py-[2px] md:!px-2 md:rounded-2xl md:!mr-2 font-inter !text-xs !font-medium
                                        !leading-[18px] bg-palette-border-palette-gray-200 text-palette-gray-700 relative z-[5]"
                                >
                                    {{ '+' + numberOverFacility }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div #containerPrice class="max-md:absolute max-md:right-0 max-md:bottom-0 md:!w-[40.5%] md:!ml-4">
                        <div class="flex flex-col !gap-1">
                            <span *ngIf="isLoading; then loading; else showPriceCache"></span>
                            <ng-template #loading>
                                <div class="max-md:relative md:!h-full flex justify-end md:items-end !pr-7">
                                    <app-loading [style]="'!h-[50px] !w-[50px]'"></app-loading>
                                </div>
                            </ng-template>
                            <ng-template #showPriceCache>
                                <span *ngIf="isLoadingAPI; then succes; else fail"></span>
                                <ng-template #fail>
                                    <div *ngIf="item?.net_price" class="max-md:relative md:!h-full flex justify-end md:items-end">
                                        <div *ngIf="!isPrice && item.origin_price !== item.net_price" class="max-md:!hidden md:!leading-tight md:flex md:items-center md:!text-base md:!ml-0.5 line-through font-inter !font-normal !text-palette-gray-500">
                                            <app-price-format [price]="item.origin_price"></app-price-format>
                                        </div>
                                        <div class="md:!ml-1.5 md:!leading-none flex items-center max-md:!text-xl max-md:!leading-[30px] md:!text-2xl font-inter !font-bold !text-palette-orange-dark-500 !ml-0.5">
                                            <app-price-format [price]="item.net_price"></app-price-format>
                                        </div>
                                    </div>
                                    <div *ngIf="!item.net_price" class="max-md:relative md:!h-full flex justify-end md:items-end">
                                        <div class="flex flex-col justify-center items-center">
                                            <img class="!w-[50px] !h-[50px]" src="assets/icons/lists_of_hotel/book-fully.svg">
                                            <p class="font-inter !text-sm !font-normal text-palette-gray-700">{{ 'SEARCH_RESULT.FULLY_BOOKED' | translate }}</p>
                                        </div>
                                    </div>
                                    <div *ngIf="item?.net_price" class="font-inter text-[13px] font-normal !text-palette-gray-500 text-end whitespace-nowrap flex justify-end">
                                        {{'PAYMENT.TOTAL_NIGHT_BEFORE_TAXE_FEE' | translate : {value: item.rental_night, syntax: item.rental_night>=2?'nights': 'night'} }} 
                                        <app-price-format class="ml-1 !font-semibold" [price]="item.total_price"></app-price-format>
                                    </div>
                                </ng-template>
                                <ng-template #succes>
                                    <div class="max-md:relative md:!h-full flex justify-end md:items-end !pr-7">
                                        <app-loading [style]="'!h-[50px] !w-[50px]'"></app-loading>
                                    </div>
                                    <!-- <div *ngIf="item?.net_price" class="max-md:relative md:!h-full flex justify-end md:items-end">
                                        <div *ngIf="!isPrice && item.origin_price !== item.net_price" class="max-md:!hidden md:!leading-tight md:flex md:items-center md:!text-base md:!ml-0.5 line-through font-inter !font-normal !text-palette-gray-500">
                                            <app-price-format [price]="item.origin_price"></app-price-format>
                                        </div>
                                        <div class="md:!ml-1.5 md:!leading-none flex items-center max-md:!text-xl max-md:!leading-[30px] md:!text-2xl font-inter !font-bold !text-palette-orange-dark-500 !ml-0.5">
                                            <app-price-format [price]="item.net_price"></app-price-format>
                                        </div>
                                    </div>
                                    <div *ngIf="item?.net_price" class="font-inter text-sm font-normal text-palette-gray-700 text-end whitespace-nowrap flex justify-end">
                                        {{'PAYMENT.PER_NIGHT_BEFORE_TAXE_FEE' | translate}}
                                    </div>
                                    <div *ngIf="!item?.net_price" class="max-md:relative md:!h-full flex justify-end md:items-end !pr-7">
                                        <app-loading [style]="'!h-[50px] !w-[50px]'"></app-loading>
                                    </div> -->
                                </ng-template>
                            </ng-template>
                            <!-- <span *ngIf="isLoadingPriceCache; then loading; else showPriceCache"></span>
                            <ng-template #loading>
                                <div *ngIf="item.net_price" class="max-md:relative md:!h-full flex justify-end md:items-end">
                                    <div *ngIf="!isPrice && item.origin_price !== item.net_price" class="max-md:!hidden md:!leading-tight md:flex md:items-center md:!text-base md:!ml-0.5 line-through font-inter !font-normal !text-palette-gray-500">
                                        <app-price-format [price]="item.origin_price"></app-price-format>
                                    </div>
                                    <div class="md:!ml-1.5 md:!leading-none flex items-center max-md:!text-xl max-md:!leading-[30px] md:!text-2xl font-inter !font-bold !text-palette-orange-dark-500 !ml-0.5">
                                        <app-price-format [price]="item.net_price"></app-price-format>
                                    </div>
                                </div>
                                <div *ngIf="!item.net_price" class="max-md:relative md:!h-full flex justify-end md:items-end !pr-7">
                                    <app-loading [style]="'!h-[50px] !w-[50px]'"></app-loading>
                                </div>
                            </ng-template>
                            <ng-template #showPriceCache> -->
                            <!-- </ng-template> -->
                        </div>
                    </div>
                </div>
                <div *ngIf="displayPolicy.length > 0" class="max-md:!hidden md:!mt-2 md:flex md:justify-between md:!h-11">
                    <div #policy class="md:!h-11 md:py-[11px] md:flex md:flex-wrap overflow-hidden">
                        <div class="w-full flex flex-wrap">
                            <app-tag class="md:!mb-2" *ngFor="let policy of displayPolicy" [type]="policy.type" [item]="policy.name"></app-tag>
                        </div>
                        <!-- <span #hotelPolicy (mouseover)="mouseOverHotelPolicy()" *ngIf="hasRemainingPolicy" class="md:!h-[22px] md:!py-[2px] md:!px-2 md:rounded-2xl md:!mr-2 font-inter !text-xs !font-medium !leading-[18px] bg-palette-border-palette-gray-200 text-palette-gray-700">{{ remainingPolicy }}</span>
                        <div *ngIf="hotelPolicyArray" class="tooltip-hotel-policy absolute inline-block !px-3 py-2 text-xs font-semibold text-center transition-opacity duration-200 ease-in bg-white rounded-lg z-100 text-palette-gray-700 whitespace-nowrap">
                            {{ slicePolicy }}
                        </div> -->
                    </div>
                    <!-- <div>
                        <a  [routerLink]="genRouterLink(item.property_id, item.property_name, item.city_name)"
                            [queryParams]="genQueryParams(item.property_id, item.property_name, item.city_name)"
                            class=" md:!w-[162px]  md:!text-base bg-palette-blue-600 flex justify-center items-center h-[44px]
                            rounded-lg font-inter !font-semibold text-palette-base-white" 
                            >
                                {{ 'SEARCH_RESULT.VIEW_AVAILABILITY' | translate }}
                        </a>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!isLogin" class="h-[60px] w-auto bg-white !px-4 !py-3 rounded-b-lg">
        <div class="bg-palette-blue-50 rounded-lg h-full flex items-center">
            <div class="font-inter text-sm flex !gap-3 !px-3">
                <span class="font-medium text-palette-gray-700">{{ 'DETAIL.GET_MEMBER_DISCOUNT' | translate }}</span>
                <div class="flex items-center !gap-1 cursor-pointer !z-[5]" (click)="onSignIn()">
                    <span class="font-semibold text-palette-blue-600 hover:underline"> {{ 'DETAIL.SIGN_IN' | translate }} </span>
                    <i class="sctr-icon-arrow-narrow-right text-palette-blue-600 text-[16px]"></i>
                </div>
            </div>
        </div>
    </div>
</div>
<app-toast keyToast="rsc"></app-toast>