<div class="flex flex-col !w-full gap-1" [formGroup]="phoneNumberForm">
    <div 
      class="flex items-center input-phone-booking"
      [ngClass]="{ 
        '!border-[#dc3545]': f['phoneNumber'].touched && f['phoneNumber'].errors,
        'focused': isFocused
      }"
    >
      <p-dropdown
        [options]="countries"
        [filter]="true"
        filterBy="name,code"
        formControlName="phoneNumberCode">
        <ng-template pTemplate="selectedItem">
          <div class="flex !gap-[2px] justify-center items-center">
            <img [src]="getIcon(f['phoneNumberCode'].value)" class="!w-6 !h-5 rounded border border-palette-base-white object-cover" />
            <span class="font-inter !text-sm !font-normal text-palette-gray-700">{{ f['phoneNumberCode'].value.code }}</span>
        </div>
        </ng-template>
        <ng-template let-country pTemplate="item">
          <div class="flex !gap-2 items-center z-1000">
            <img [src]="country.icon" class="!w-6 !h-5 rounded border border-palette-base-white object-cover" />
            <span class="font-inter !text-sm">{{ country.name }}</span>
            <span class="font-inter !text-sm">({{ country.code }})</span>
          </div>
        </ng-template>
      </p-dropdown>
      <input
        [id]="id"
        (focus)="onFocus()"
        (blur)="onBlur()"
        [maxlength]="15"
        [placeholder]="'OTA.CONTRACT_AGREEMENT.P_PHONE_NUMBER' | translate" 
        class="phone-input w-full !pl-3 outline-none font-inter !text-sm !font-normal"
        type="number"
        (paste)="onPaste($event)"
        (keypress)="onKeyPress($event)"
        formControlName="phoneNumber"
      />
    </div>
    <div *ngIf="f['phoneNumber'].touched && f['phoneNumber'].errors" class="invalid-feedback">
      <div *ngIf="f['phoneNumber'].errors['required']" class="font-inter text-sm flex items-center gap-1">
          <span class="flex items-center"><i class="sctr-icon-alert-circle"></i></span>
          {{'OTA.CONTACT.PHONE_NUMBER' | translate}} {{'OTA.CONTACT.IS_REQUIRED' | translate}}
      </div>
      <div *ngIf="f['phoneNumber'].errors['pattern']" class="font-inter text-sm flex items-center gap-1">
          <span class="flex items-center"><i class="sctr-icon-alert-circle"></i></span>
          {{'OTA.CONTACT.PHONE_NUMBER' | translate}} {{'OTA.CONTACT.IS_INVALID' | translate}}
      </div>
    </div>
  </div>