<div id="menu-bar" [ngClass]='handleMenuBar()' class="h-auto md:h-[68px] whitespace-nowrap  top-0 z-20 w-full">
    <lib-header
        #headerLib
        (updateLanguage)="onchangeLanguageAndCurrency($event)"
        [environment]="environment"
        [userInfo]="userInfo"
        [currentPage]="'booking'"
        [currentTheme]="currentTheme"
        [(megaVisible)]="megaVisible"
        [myBookingUrl]="myBookingUrl"
        [visibleShopingCart]="visibleShoppingCart"
        [loginReturnUrl] = "loginReturnUrl">
    </lib-header>
</div>