import { CommonModule } from '@angular/common';
import { Component, ElementRef, Renderer2, ViewChild, ChangeDetectorRef, Input, AfterViewInit, HostListener, OnInit } from '@angular/core';
import { ShowImageModalComponent } from '../show-image-modal/show-image-modal.component';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Reviewer } from '@modules/hotel-booking/models/customer-review.model';
import { Media } from '@modules/hotel-booking/models/customer-review.model';
import { environment } from '@env/environment';
import { TooltipTheme, TooltipPosition } from '@shared/enum/tooltip.enums';
import {
  MAX_TRUNCATED_TEXT_HEIGHT_COMMENT,
  MAX_TRUNCATED_TEXT_HEIGHT,
} from '@shared/constant';
import { MulTranslationService } from 'src/app/core/services/mul-translation/mul-translation.service';
import { MessageService } from 'primeng/api';
import { SoctripTranslationService } from 'src/app/services/soctrip-translation/soctrip-translation.service';
import { TranslateParamModel } from 'src/app/lib/api/translate/api.translate.model';
import { ToastType } from '@shared/enum/toast-type';
@Component({
  selector: 'app-customer-review-card',
  templateUrl: './customer-review-card.component.html',
  styleUrls: ['./customer-review-card.component.scss'],
  providers: [DialogService, MessageService],
})
export class CustomerReviewCardComponent implements AfterViewInit, OnInit {
  @ViewChild('reviewContent') reviewContent: ElementRef;
  @Input() item: Reviewer;
  isExpanded: boolean = true;
  isHelpful: boolean = false;
  isReported: boolean = false;
  isLongText: boolean = false;
  openImageRef: DynamicDialogRef | undefined;
  focusedImageIndex = -1;
  baseUrl = environment.BASE_PATH;

  TooltipPosition: typeof TooltipPosition = TooltipPosition;
  TooltipTheme: typeof TooltipTheme = TooltipTheme;
  listTypeRoomToolTip: string = '';
  textTranslated: string;
  contentHeight = 0;
  isTranslate = false;
  isShowAllContent = false;
  MAX_TRUNCATED_TEXT_HEIGHT_COMMENT = MAX_TRUNCATED_TEXT_HEIGHT_COMMENT;
  isLiveView = false;
  isEdit = false;

  constructor(
    private cdRef: ChangeDetectorRef,
    private renderer: Renderer2,
    private ref: DynamicDialogRef,
    private mulTranslationService: MulTranslationService,
    public dialogService: DialogService,
    private messageService: MessageService,
    private soctripTranslationService: SoctripTranslationService,
  ) {}

  ngOnInit(): void {
    this.listTypeRoomToolTip = "<p> "
    for (let i = 1; i < this.item.factors.length; i++) {
      this.listTypeRoomToolTip += this.item.factors[i].type
      this.listTypeRoomToolTip += '<br>';
    }
    this.listTypeRoomToolTip += '</p>';
  }

  toggleContent(): void {
    this.isExpanded = !this.isExpanded;
  }

  handleClickHelpful(): void {
    this.isHelpful = !this.isHelpful;
  }

  handleClickReport(): void {
    this.isReported = !this.isReported;
  }

  replaceAvatarWithDefaultImage(event: any): void {
    event.target.src = `${this.baseUrl}${this.item.created_by.avatar_thumbnail_url}`;
  }

  handleShowViewMore(els: ElementRef, height: number): void {
    const elementHeight = els.nativeElement.offsetHeight;
    if (elementHeight > height) {
      this.isLongText = true;
    } else {
      this.isLongText = false;
    }
    this.isExpanded = false;
  }

  ngAfterViewInit(): void {
    this.handleShowViewMore(this.reviewContent, 24 * 3);
    this.cdRef.detectChanges();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    const elementHeight = this.reviewContent.nativeElement.offsetHeight;

    if (elementHeight > 24 * 3) {
      this.isLongText = true;
      this.isExpanded = false;
      this.renderer.addClass(this.reviewContent.nativeElement, 'line-clamp-3');
    }

    if (elementHeight == 24 * 3) {
      this.renderer.removeClass(
        this.reviewContent.nativeElement,
        'line-clamp-3'
      );
      const actualHeight = this.reviewContent.nativeElement.offsetHeight;

      if (actualHeight > 24 * 3) {
        this.isLongText = true;
        this.renderer.addClass(
          this.reviewContent.nativeElement,
          'line-clamp-3'
        );
      } else this.isLongText = false;
      this.isExpanded = false;
    }

    if (elementHeight < 24 * 3) {
      this.isLongText = false;
      this.isExpanded = false;
    }
  }
  onClickImage(image_urls: any, imageIndex: number) {
    let images: string[] = [];
    image_urls.map((img: Media) => {
      let temp = `${this.baseUrl}/storage/files/web/${img.id}`;
      images.push(temp);
    });
    this.openImageRef = this.dialogService.open(ShowImageModalComponent, {
      data: {
        image_id: imageIndex,
        image_urls: image_urls,
        multiMedia: true
      },
      showHeader: false,
      width: '100%',
      height: '100%',
      baseZIndex: 10000,
      maximizable: true,
    });
    this.openImageRef.onClose.subscribe(() => {});
    this.openImageRef.onMaximize.subscribe((value: any) => {});
  }
  replaceWithDefaultImage(event: any): void {
    event.target.src = 'assets/images/temporary/default-hotel.png';
  }
  onTranslateText() {
    this.textTranslated = '';
    const selectedLangCode = this.mulTranslationService
      .getKeyLocalUserProfile('language')
      ?.toLowerCase();
    this.isTranslate = !this.isTranslate;
    this.getTranslateText(selectedLangCode?.toLowerCase() || 'en');
    if (this.contentHeight > MAX_TRUNCATED_TEXT_HEIGHT) {
      this.isShowAllContent = true;
    }
  }

  getTranslateText(target: string) {
    let content = this.item.content;
    content = this.markHashtagsForTranslation(content);
    const params: TranslateParamModel = {
      q: content.trim(),
      source: 'auto', // TODO: Implement dectect language when `comment.language` is implemented
      target: target,
      format: 'html',
    };
    this.mulTranslationService.translateText(params).subscribe((res: any) => {
      if (res && res.success) {
        this.textTranslated = res?.data.translatedText;
      } else {
        this.isTranslate = false;
        this.messageService.add({
          severity: ToastType.SUCCESS,
          detail: this.soctripTranslationService.getTranslation('TRANSLATION.TRANSLATE_FAILED'),
        });
      }
    });
  }

  formatContentTranslation(content: string) {
    let outputString =
      this.mulTranslationService.replaceHashtagTranslation(content);
    return this.replaceLinkTag(outputString);
  }

  formattedContent() {
    let content = this.item.content;
    content = this.replaceLinkTag(content);
    content = this.mulTranslationService.urlify(content);
    return content.replace(/\n/g, '<br>');
  }

  replaceLinkTag(content: string) {
    let tempConent = content;
  
    return tempConent;
  }

  markHashtagsForTranslation(content: string) {
    let tempConent = content;
    tempConent = tempConent?.replace(/\n/g, '<br />');
    return tempConent;
  }

  handleChangeLanguage(event: any) {
    this.textTranslated = '';
    this.getTranslateText(event.value.code);
  }

  translateContent() {
    return this.formatContentTranslation(this.textTranslated);
  }

  isShowTranslateButton(item: any) {
    if (!this.formattedContent()) return false;
    return this.mulTranslationService.checkLanguage(
      item?.content,
      'en'
    );
  }

  isFileVideo(type: string){
    return type.includes('video');
  }

  ngOnDestroy() {
    if (this.openImageRef) {
      this.ref.close();
    }
  }
}