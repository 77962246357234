export const environment = {
  production: false,
  baseURL: 'https://api-dev-tmtrav.tma-swerp.com',
  BASE_PATH: 'https://api-dev-tmtrav.tma-swerp.com',
  BASE_API_URL: 'https://api-dev-tmtrav.tma-swerp.com',
  SOCIAL_APP_URL: 'https://tmtrav-dev.tma-swerp.com',
  TRAVEL_ASSIST_URL: 'https://tmtrav-travel-dev.tma-swerp.com/tour',
  RESTAURANT_URL: 'https://tmtrav-food-dev.tma-swerp.com',
  SHOP_URL: 'https://tmtrav-ecommerce-dev.tma-swerp.com',
  BOOKING_URL: 'https://tmtrav-booking-dev.tma-swerp.com',
  PAYMENT_URL: 'https://payment-dev.tma-swerp.com/order',
  FLIGHT_URL: 'https://tmtrav-flight-dev.tma-swerp.com',
  CAR_URL: 'https://tmtrav-car-rental-dev.tma-swerp.com/booking/car',
  ECOMMERCE_FOOD_URL: 'https://tmtrav-food-dev.tma-swerp.com',
  ECOMMERCE_URL: 'https://tmtrav-ecommerce-dev.tma-swerp.com',
  CAR_RENTAL_URL: 'https://tmtrav-car-rental-dev.tma-swerp.com/booking/car',
  SOCTRIP_CENTER_URL: 'https://tmtrav-business-central-dev.tma-swerp.com/home',
  ECOMMERCE_MGMT_URL: 'https://tmtrav-ecommerce-management-dev.tma-swerp.com',
  AFFILIATE_URL: 'https://travel-affiliate.beta.soctrip.com',
  languages: ['en', 'vi'],
  PROPERTY_DATA_SERVICE_API_BASE_PATH:
    'https://api-dev-tmtrav.tma-swerp.com/booking-property-data',
  RATING_SERVICE_API_BASE_PATH: 'https://api-dev-tmtrav.tma-swerp.com/rating',
  PROPERTY_SEARCH_SERVICE_API_BASE_PATH:
    'https://api-dev-tmtrav.tma-swerp.com/booking-property-search',
  USER_AND_GUEST_SERVICE_API_BASE_PATH:
    'https://api-dev-tmtrav.tma-swerp.com/booking-user-data',
  FAMOUS_SPOT_SERVICE_API_BASE_PATH:
    'https://api-dev-tmtrav.tma-swerp.com/booking-famous-spot',
  BOOKING_GENERIC_SERVICE_API_BASE_PATH:
    'https://api-dev-tmtrav.tma-swerp.com/booking-generic',
  BOOKMARK_SERVICE_API_BASE_PATH:
    'https://api-dev-tmtrav.tma-swerp.com/bookmarks',
  SHIPMENT_SERVICE_API_BASE_PATH:
    'https://api-dev-tmtrav.tma-swerp.com/shipment',
  TM_TRAV_MAP_LOCATION_SERVICE_API_BASE_PATH:
    'https://api-dev-tmtrav.tma-swerp.com/map-location',
  STORAGE_SERVICE_API_BASE_PATH: 'https://api-dev-tmtrav.tma-swerp.com/storage',
  STORAGE_SERVICE_API_BASE_PATH_ID_IMAGE:
    'https://api-dev-tmtrav.tma-swerp.com/storage/files/web',
  STORAGE_SERVICE_API_BASE_PATH_STREAM_VIDEO: 
    'https://api-dev-tmtrav.tma-swerp.com/storage/files/stream-video',
  PROPERTY_ROOM_API_BASE_PATH:
    'https://api-dev-tmtrav.tma-swerp.com/booking-property-room',
  BOOKING_PRICING_API_BASE_PATH:
    'https://api-dev-tmtrav.tma-swerp.com/booking-pricing',
  BOOKING_USER_DATA_API_BASE_PATH:
    'https://api-dev-tmtrav.tma-swerp.com/booking-user-data',
  ANGULAR_BOOKING_USER_DATA_API_BASE_PATH:
    'https://api-dev-tmtrav.tma-swerp.com/booking-user-data',
  BOOKING_FLIGHT_API_BASE_PATH:
    'https://api-dev-tmtrav.tma-swerp.com/booking-flight-search',
  ECOMMERCE_FINANCE_API_BASE_PATH:
    'https://api-dev-tmtrav.tma-swerp.com/ecommerce-finance',
  ECOMMERCE_WALLET_API_BASE_PATH:
    'https://api-dev-tmtrav.tma-swerp.com/ecommerce-wallet',
  ORDER_API_BASE_PATH: 'https://api-dev-tmtrav.tma-swerp.com/order',
  BOOKING_RESERVATION_API_BASE_PATH:
    'https://api-dev-tmtrav.tma-swerp.com/booking-reservation',
  BOOKING_FAMOUS_SPOT_API_BASE_PATH:
    'https://api-dev-tmtrav.tma-swerp.com/booking-famous-spot',
  REGIONAL_API_BASE_PATH: 'https://api-dev-tmtrav.tma-swerp.com/regional',
  BOOKING_FLIGHT_BASE_PATH:
    'https://api-dev-tmtrav.tma-swerp.com/booking-flight-data',
  BOOKING_DASHBOARD: 'https://api-dev-tmtrav.tma-swerp.com/booking-dashboard',
  BOOKING_ADMIN_BASE_PATH: 'https://api-dev-tmtrav.tma-swerp.com/booking-admin',
  
  TOKEN_KEY: 'access_token',
  REFRESH_TOKEN_KEY: 'refresh_token',
  BLOOM_FILTER: 'bloom_filter',
  USER_ROLE_KEY: 'user_role',
  REFRESH_TOKEN_EXPIRY_KEY: 'refresh_token_expiry_date',
  ACCESS_TOKEN_EXPIRY_KEY: 'refresh_token_expiry_date',
  AUTH_STATUS: 'auth_status',
  USER_PROFILE: 'user_profile',
  support_languages: ['en', 'vi'],
  support_currency: ['USD', 'VND', 'EUR', 'INR', 'AUD'],
  USER_UUID: 'user_uuid_generate',
  ENVIRONMENT: 'dev',
  PRODUCT_NAME: 'Soctrip',
  SESSION_ID: 'session_id',
  SOFT_SESSION_ID: 'soft_session_id',
  FEEDBACK_URL: 'https://forms.gle/eX9Jb2eDMPuw6Hky9',
  socialAuthenIds: {
    google:
      '802332645501-0aiir74m36114t6v974bcrg4jrf8k1do.apps.googleusercontent.com',
    facebook: '2619489051654463',
  },
  MY_HOTEL_BOOKINGS:
    'https://tmtrav-booking-dev.tma-swerp.com/my-booking/hotel',
  API_TIMEOUT: 10000,
};
