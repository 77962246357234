import {
  Component,
  Input,
  ViewChild,
  ElementRef,
  HostListener,
  AfterViewInit,
  Renderer2,
  OnInit,
  ChangeDetectorRef,
  ViewChildren,
  QueryList,
  OnDestroy,
} from '@angular/core';
import { MessageService } from 'primeng/api';
import { ToastType } from '@shared/enum/toast-type';
import { Policy } from '@modules/hotel-booking/models/hotel-result.model';
import {
  IProperty,
  PropertyAmenityList,
} from '@modules/hotel-booking/models/hotel-search.model';
import { createSlug } from '@shared/utils/helper';
import { ActivatedRoute, Router } from '@angular/router';
import { BookMarkService } from '@modules/hotel-booking/services/bookmark/bookmark.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { QuickViewHotelPhotoModal } from '../quick-view-hotel-photo/quick-view-hotel-photo.component';
import { environment } from '@env/environment';
import { Subject } from 'rxjs';
import { UserService } from 'src/app/core/services/user/user.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TooltipPosition, TooltipTheme } from '@shared/enum/tooltip.enums';
import { SearchService } from '@modules/hotel-booking/services/search/search.service';
import { PlaceType } from '@shared/enum/recommend-place.enum';
import { SearchBarService } from '@modules/hotel-booking/services/search-bar.service';

@Component({
  selector: 'app-hotel-result-card',
  templateUrl: './hotel-result-card.component.html',
  providers: [MessageService, TranslateModule],
  styleUrls: ['./hotel-result-card.component.scss'],
})
export class HotelResultCardComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild('tagLine1') tagLine1: ElementRef;
  @ViewChild('tagLine2') tagLine2: ElementRef;
  @ViewChild('container') container: ElementRef;
  @ViewChild('containerPrice') containerPrice: ElementRef;
  @ViewChild('tag') listContainer: ElementRef;
  @ViewChild('policy', { static: true }) listPolicy: ElementRef;
  @ViewChildren('hotelPolicy') hotelPolicyElements: QueryList<ElementRef>;
  @ViewChildren('hotelName') hotelNameElements: QueryList<ElementRef>;
  @ViewChildren('hotelfacility') hotelFacilityElements: QueryList<ElementRef>;
  @ViewChildren('hotelAddress') hotelAddressElements: QueryList<ElementRef>;
  @ViewChildren('hotelDistance') hotelDistanceElements: QueryList<ElementRef>;
  @Input() item: IProperty;
  @Input() currentPage: number;
  @Input() isLoadingAPI: boolean;
  @Input() isLoading: boolean;
  @Input() numOfPropertiesAPI2: number;

  TooltipPosition: typeof TooltipPosition = TooltipPosition;
  TooltipTheme: typeof TooltipTheme = TooltipTheme;

  h1Element: HTMLElement | null = null;
  elementWidth: number = 0;
  elementHeight: number = 0;

  isMouse: any;

  ngUnsubscribe = new Subject<void>();
  hotelPolicyArray: any;
  hotelFacilityArray: any;
  hotelNameArray: any;
  hotelAddressArray: any;
  hotelDistanceArray: any;

  remainingFacility: string;
  hasRemainingFacility: boolean = false;
  numberOverFacility: number;
  isTooltipFacility: boolean;
  remainingPolicy: number | string;
  hasRemainingPolicy: boolean = false;
  isPrice: boolean = false;
  isVote: boolean = false;
  sliceFacility: string;
  slicePolicy: string;

  resizeTimeout: any;
  itemTagFacility: number[] = [];
  itemTagPolicy: number[] = [];
  displayFacility1: PropertyAmenityList[];
  displayFacility2: PropertyAmenityList[];
  displayPolicy: Policy[] = [];

  showScoreTooltip = false;
  showStarTooltip = false;
  ref: DynamicDialogRef | undefined;
  listImages: string[] = [];
  baseUrl = environment.STORAGE_SERVICE_API_BASE_PATH_ID_IMAGE;
  baseImageId = environment.STORAGE_SERVICE_API_BASE_PATH_ID_IMAGE;

  isLogin: boolean | null;
  isBookmarked: boolean;

  resizeTimer: any;
  isLoadingPriceCache: boolean;

  constructor(
    private searchBarService: SearchBarService,
    private messageService: MessageService,
    private cdRef: ChangeDetectorRef,
    private renderer: Renderer2,
    private router: Router,
    private route: ActivatedRoute,
    private bookMarkService: BookMarkService,
    public dialogService: DialogService,
    private userService: UserService,
    private translateService: TranslateService,
    private searchService: SearchService
  ) {}

  ngOnInit(): void {
    this.checkIsLogin();
    this.item.room_amenity_list.filter((item: any) => {
      if (item.amenity_name === 'Spa') {
        item.amenity_icon = 'Spa';
      }
    });
    this.displayFacility1 = this.item.room_amenity_list;
    this.displayFacility2 = this.item.room_amenity_list;
    this.calculatePolicy();
    this.checkBookmark();
    this.searchService
      .isLoadingPrice()
      .subscribe((isLoading) => (this.isLoadingPriceCache = isLoading));

    this.searchBarService.getQueryParamData().subscribe((res: any) => {
      this.item.rental_night = this.countDay(res.checkin, res.checkout);
    });
  }

  ngAfterViewInit(): void {
    this.responsivePrice();
    this.updateDataViewFacility();
    // this.calculateSumItem(this.itemTagFacility, this.listContainer);
    // this.calculateSumItem(this.itemTagPolicy, this.listPolicy);
    // this.updateViewFacility(2);
    // this.updateViewPolicy(1);
    // this.cdRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  checkIsLogin() {
    this.userService.getLoginStatus().subscribe((isLogin) => {
      this.isLogin = isLogin;
    });
  }

  checkBookmark() {
    this.bookMarkService.isBookmarkExists(this.item.property_id).subscribe({
      next: (isBookmarked) => {
        this.isBookmarked = isBookmarked;
      },
    });
  }

  calculatePolicy() {
    if (this.item.is_free_breakfast) {
      this.displayPolicy.push({
        name: 'Breakfast',
        type: 'warning',
      });
    }
    if (this.item.is_free_cancellation) {
      this.displayPolicy.push({
        name: 'Free cancellation',
        type: 'success',
      });
    }
    if (!this.item.is_prepayment_required) {
      this.displayPolicy.push({
        name: 'No prepayment required',
        type: 'error',
      });
    }
    // this.displayPolicy.push(
    //   {
    //     name: 'Breakfast',
    //     type: 'warning',
    //   },
    //   {
    //     name: 'Free cancellation',
    //     type: 'success',
    //   },
    //   {
    //     name: 'No prepayment required',
    //     type: 'error',
    //   }
    // )
  }

  calculateSumItem(itemTag: number[], el: ElementRef) {
    const frameTag = el.nativeElement;
    const tags = frameTag.children;
    let sumSizeTag = 0;
    for (let index = 0; index < tags.length; index++) {
      const tag = tags[index];
      if (tag.nodeType === Node.ELEMENT_NODE) {
        const childElement = tag as HTMLElement;
        sumSizeTag += childElement.offsetWidth;
        itemTag.push(sumSizeTag);
      }
    }
  }

  handleBookmark(event: any) {
    event.stopPropagation();
    if (!this.isLogin) {
      this.messageService.add({
        key: 'hd',
        severity: ToastType.WARNING,
        detail: 'Please login before bookmark',
      });
      return;
    }
    if (this.isBookmarked) {
      this.bookMarkService.removeBookMark(this.item.property_id).subscribe({
        next: (response) => {
          if (response.success) {
            this.isBookmarked = false;
            this.bookMarkService.getBloomFilter();
            this.messageService.add({
              key: 'rsc',
              severity: ToastType.SUCCESS,
              detail: this.translateService.instant('BOOKMARK.DELETE'),
            });
          }
        },
      });
    } else {
      this.bookMarkService.addBookMark(this.item.property_id).subscribe({
        next: (response) => {
          if (response.success) {
            this.isBookmarked = true;
            this.bookMarkService.getBloomFilter();
            this.messageService.add({
              key: 'rsc',
              severity: ToastType.SUCCESS,
              detail: this.translateService.instant('BOOKMARK.ADD'),
            });
          }
        },
      });
    }
  }

  responsivePrice() {
    if (
      (window.innerWidth <= 1560 && window.innerWidth >= 1280) ||
      (window.innerWidth <= 1080 && window.innerWidth >= 768) ||
      (window.innerWidth <= 768 && window.innerWidth >= 0)
    ) {
      this.isPrice = true;
    } else this.isPrice = false;
    this.cdRef.detectChanges();
  }

  getPropertyImageId(item: IProperty) {
    return item.property_image_ids
      ? `${this.baseUrl}/${item?.property_image_ids[0]}.webp`
      : 'assets/images/temporary/default-hotel.png';
  }

  showAllFacility(): void {
    this.displayFacility1 = this.item.room_amenity_list;
  }

  showAllPolicy(): void {
    this.displayPolicy = [];
  }

  onSignIn() {
    window.location.href = environment.SOCIAL_APP_URL + '/login?r='+ encodeURIComponent(window.location.href);
  }

  removeDuplicates(a: any, b: any) {
    let setA = new Set(a);
    b = b.filter((item: any) => !setA.has(item));
    return b;
  }

  updateDataViewFacility() {
    setTimeout(() => {
      const widthContainer = this.container.nativeElement.offsetWidth;
      const widthPrice = this.containerPrice.nativeElement.offsetWidth;
      const widthFacility = widthContainer - (widthPrice + 16);
      const frameTag1 = this.tagLine1?.nativeElement;
      const tags1 = frameTag1?.children;
      let sumSizeTag = 0;
      for (let index = 0; index < tags1?.length; index++) {
        const tag = tags1[index];
        if (tag.nodeType === Node.ELEMENT_NODE) {
          const childElement = tag as HTMLElement;
          sumSizeTag += childElement.offsetWidth;
          if (sumSizeTag > widthFacility) {
            this.displayFacility1 = this.item.room_amenity_list.slice(0, index);
            this.displayFacility2 = this.item.room_amenity_list.slice(index);
            sumSizeTag = 0;
            break;
          }
        }
      }
      if (this.item.room_amenity_list.length < 2) {
        this.displayFacility2 = [];
        return;
      }
      const frameTag2 = this.tagLine2?.nativeElement;
      const tags2 = frameTag2?.children;
      for (let index = 0; index < tags2?.length; index++) {
        const tag = tags2[index];
        if (tag.nodeType === Node.ELEMENT_NODE) {
          const childElement = tag as HTMLElement;
          sumSizeTag += childElement.offsetWidth;
          if (sumSizeTag > widthFacility - 100) {
            const temp = [...this.displayFacility2];
            this.displayFacility2 = [...temp.slice(0, index)];
            if (temp.length !== this.displayFacility2.length) {
              this.hasRemainingFacility = true;
              this.remainingFacility = temp
                .slice(index)
                .map((item) => item.amenity_name)
                .join(', ');
              this.numberOverFacility = temp.slice(index).length;
            } else {
              this.remainingFacility = '';
              this.hasRemainingFacility = false;
            }
            sumSizeTag = 0;
            break;
          }
        }
      }
      this.displayFacility2 = this.removeDuplicates(
        this.displayFacility1,
        this.displayFacility2
      );
    }, 100);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.responsivePrice();
    if (window.innerWidth <= 768 && window.innerWidth >= 0) {
      this.isVote = true;
    } else this.isVote = false;
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
    }
    this.resizeTimeout = setTimeout(() => {
      this.displayFacility1 = this.item.room_amenity_list;
      this.displayFacility2 = this.item.room_amenity_list;
      this.updateDataViewFacility();
    }, 250);
  }

  handleShowTooltip(els: ElementRef[], className: string): void {
    for (var val of els) {
      const isTruncated =
        val.nativeElement.scrollWidth > val.nativeElement.clientWidth;
      if (isTruncated) {
        this.renderer.addClass(val.nativeElement, className);
      } else {
        this.renderer.removeClass(val.nativeElement, className);
      }
    }
  }

  onMouseTooltipFacility(e: MouseEvent) {
    const x = e.clientX + window.scrollX;
    const y = e.clientY + window.scrollY;
    const html = `
      <div class="bg-[#475467] relative rounded-lg text-white py-2 px-4 font-semibold text-xs shadow-lg opacity-1 z-[6] max-w-xs">
        ${this.remainingFacility}
      </div>
    `;
    if (!this.h1Element) {
      this.h1Element = document.createElement('div');
      this.h1Element.innerHTML = html;
      this.h1Element.style.position = 'absolute';
      document.body.appendChild(this.h1Element);
      this.elementWidth = this.h1Element.offsetWidth;
      this.elementHeight = this.h1Element.offsetHeight;
    }
    this.h1Element.style.left = x - this.elementWidth + 'px';
    this.h1Element.style.top = y - this.elementHeight + 'px';
  }

  onMouseLeaveTooltipFacility() {
    if (this.h1Element && this.h1Element.parentNode) {
      this.h1Element.parentNode.removeChild(this.h1Element);
      this.h1Element = null;
    }
  }

  mouseOverHotelDistance() {
    this.hotelDistanceArray = this.hotelDistanceElements.toArray();
    this.handleShowTooltip(this.hotelDistanceArray, 'hotel-distance');
  }

  handleClickView(event: Event) {
    event.stopPropagation();
    this.messageService.add({
      severity: ToastType.WARNING,
      detail: 'This feature is not developed yet',
    });
  }

  convertDoubleToInt(num: any) {
    return num | 0;
  }

  formatCurrency(price: number) {
    return price?.toLocaleString('en-US');
  }

  genRouterLink(id: string, name: string, city_name: string) {
    const addressArray = city_name.split(',');
    let cityNameSlug = createSlug(addressArray[addressArray.length - 1]);
    if (cityNameSlug == '' || !cityNameSlug) {
      cityNameSlug = 'property';
    }
    let hotelNameSlug = createSlug(name);
    if (hotelNameSlug == '' || !hotelNameSlug) {
      hotelNameSlug = 'hotel-name';
    }
    return `/booking/hotel/search/${cityNameSlug}/${hotelNameSlug}`;
  }

  genQueryParams(id: string, name: string, address: string) {
    const queryParams = { ...this.route.snapshot.queryParams, property_id: id };
    return queryParams;
  }

  viewMap(item: IProperty) {
    const genQueryParams = this.genQueryParams(
      item.property_id,
      item.property_name,
      item.city_name
    );
    this.router.navigate(['/booking/hotel/map', this.item.property_name], {
      queryParams: {
        ...genQueryParams,
        dest_type: PlaceType.PROPERTY.toUpperCase(),
      },
    });
  }

  convertImgIdsToImages(images: string[]) {
    return images.map((image) => this.baseUrl + '/' + image + '.webp');
  }

  checkHover() {
    this.isMouse = true;
  }

  showImg(e: Event) {
    e.stopPropagation();
  }

  quickViewPhotos(event: Event, images: string[]) {
    event.stopPropagation();
    this.ref = this.dialogService.open(QuickViewHotelPhotoModal, {
      data: {
        selectedImage: images,
      },
      showHeader: false,
      contentStyle: { overflow: 'auto' },
      baseZIndex: 10000,
      maximizable: true,
      dismissableMask: true,
    });
    this.ref.onClose.subscribe((data: any) => {});
    this.ref.onMaximize.subscribe((value: any) => {});
  }

  checkImageList(property_image_urls: any, property_image_ids: any) {
    if (property_image_ids.length < 1 || property_image_ids == null) {
      return property_image_urls;
    } else {
      return this.convertImgIdsToImages(property_image_ids);
    }
  }

  replaceWithDefaultImage(event: any): void {
    event.target.src = 'assets/images/temporary/default-hotel.png';
  }

  countDay(check_in: string, check_out: string): number {
    const checkin = new Date(check_in);
    const checkout = new Date(check_out);

    const checkinTimestamp = checkin.getTime();
    const checkoutTimestamp = checkout.getTime();

    const millisecondsInDay = 1000 * 60 * 60 * 24;
    const daysDifference = Math.floor(
      (checkoutTimestamp - checkinTimestamp) / millisecondsInDay
    );

    return daysDifference;
  }
}
