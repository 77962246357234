import { Component, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';
import { BehaviorSubject, Observable, Subject, defer, of, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PropertyManagementService {
  private _confirmSubject = new Subject<boolean>();
  private isUnderReview = new BehaviorSubject<boolean>(false);
  private propertyStatus = new BehaviorSubject<string>('');
  private resetDataContract = new BehaviorSubject<any>(null);

  constructor(private confirmationService: ConfirmationService, private translateService: TranslateService) { }

  setActivateRoute(data: boolean) {
    this._confirmSubject.next(data);
  }
  getActivateRoute() {
    return this._confirmSubject.asObservable();
  }


  setPropertyStatus(isUnderReview: boolean) {
    this.isUnderReview.next(isUnderReview);
  }

  getSignalResetDataContract() {
    return this.resetDataContract.asObservable();
  }

  setSignalResetDataContract(signal: any) {
    this.resetDataContract.next(signal);
  }

  getPropertyStatus() {
    return this.isUnderReview.asObservable();
  }

  setHotelStatus(propertyStatus: string) {
    this.propertyStatus.next(propertyStatus);
  }

  getHotelStatus() {
    return this.propertyStatus.asObservable();
  }


  canDeactivate(isEdited: boolean): boolean | Observable<boolean> { 
    if (isEdited) {
      this.confirmationService.confirm({
        header: this.translateService.instant('COMMON.DISCARD_CHANGES'),
        message: this.translateService.instant(
          'OTA_MANAGEMENT.PROPERTY_PHOTOS.DISCARD_MESSAGE'
        ),
        rejectLabel: this.translateService.instant('COMMON.DISCARD'),
        acceptLabel: this.translateService.instant('COMMON.KEEP'),
        rejectButtonStyleClass: 'btn-save-as-draft-reject',
        acceptButtonStyleClass: 'btn-save-as-draft-accept',
        dismissableMask: true,
        defaultFocus: 'none',
        key: 'property-manager-cancel-popup',
        reject: (event: number) => {
          //  Rejects have two types:
          // The button in the footer is 1.
          // The X icon in the header is 2.        
          if (event === 1) {
            this.setActivateRoute(true)
          }
        },
        accept: () => {
          this.setActivateRoute(false)
        },
      });
    } else {
      return true;
    }
    return this.getActivateRoute()
  }

}
