import { Component, HostListener, OnInit } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { FooterComponent } from '@layout/footer/footer.component';
import { HeaderBookingComponent } from '@layout/header/header.component';
import { AuthService } from './services/auth.service';
import { TokenStorageService } from './services/token-storage.service';
import { UserService } from 'src/app/core/services/user/user.service';
import { PermissionService } from './core/services/permission/permission.service';
import { ButtonModule } from 'primeng/button';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@env/environment';
import { BookMarkService } from '@modules/hotel-booking/services/bookmark/bookmark.service';
import { SharedModule } from '@shared/shared.module';
import { SafeResourceUrl } from '@angular/platform-browser';
import { FeedbackButtonComponent } from '@shared/components/feedback-button/feedback-button.component';
import { FooterService } from '@modules/hotel-booking/services/footer/footer.service';
import { Subject, takeUntil } from 'rxjs';
import { PrimeNGConfig } from 'primeng/api';
import { GeolocationService } from './services/geolocation/geolocation.service';
import { AUTH_GUARD_ROUTES } from '@shared/constants/constants';
import { SoctripTranslationService } from './services/soctrip-translation/soctrip-translation.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    /* primeng */
    ButtonModule,
    /* @angular */
    RouterOutlet,
    /* @components */
    FooterComponent,
    HeaderBookingComponent,
    SharedModule,
    FeedbackButtonComponent,
  ],
  template: `
    <div class="root-component">
      <app-header />
      <router-outlet></router-outlet>
      <app-popup-cta></app-popup-cta>
      <app-footer />
    </div>
  `,
})
export class AppComponent implements OnInit {
  //TODO: Replace with actual code
  loginForm = { password: '123456a@A', username: 'testaccount' };
  userRole: string[] = [];
  socialApp: string = environment.SOCIAL_APP_URL;
  socialAppConnectUrl: string = environment.SOCIAL_APP_URL + '/app-connect';
  socialAppSafeUrl: SafeResourceUrl;
  isLoading: boolean = true;
  translatedCalendar: any
  private ngUnsubscribe = new Subject<void>();

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private tokenStorageService: TokenStorageService,
    private router: Router,
    private footerService: FooterService,
    private permissionService: PermissionService,
    private translateService: TranslateService,
    private bookMarkService: BookMarkService,
    private config: PrimeNGConfig,
    private geolocationService: GeolocationService,
    private soctripTranslationService: SoctripTranslationService
  ) {
    this.bookMarkService.getBloomFilter();
    this.translateService.addLangs(environment.support_languages);
    // this.userService
    //   .getUserProfile()
    //   .pipe(takeUntil(this.ngUnsubscribe))
    //   .subscribe((user) => {
    //     if (user) {
    //       if (
    //         user.language &&
    //         environment.support_languages.includes(user.language.toLowerCase())
    //       ) {
    //         const lang = user.language.toLowerCase();
    //         this.translateService.setDefaultLang(lang);
    //         localStorage.setItem('lang', lang);
    //       } else {
    //         this.translateService.setDefaultLang(
    //           environment.support_languages[0]
    //         );
    //         localStorage.setItem('lang', environment.support_languages[0]);
    //       }
          

    //     } else {
    //       localStorage.setItem('lang', environment.support_languages[0]);
    //       // localStorage.setItem('currency', environment.support_currency[0]);
    //       this.translateService.setDefaultLang(
    //         environment.support_languages[0]
    //       );
    //       this.setTranslateCalendar();
    //       // this.footerService.setCurrency({
    //       //   code: environment.support_currency[0],
    //       // });
    //     }
    //   });
  }

  ngOnInit(): void {
    localStorage.setItem('ignore-secure', '');
    this.connectPMServer();
    this.checkPermission();
    this.setTranslateCalendar();
  }

  checkPermission(): void {
    this.geolocationService.checkGeolocationPermission().then(status => {
      if (status !== 'granted') {
        this.getLocation();
      }
    });
  }

  getLocation(): void {
    this.geolocationService.requestLocation().then();
  }

  setTranslateCalendar() {
    this.translatedCalendar = {
      dayNames: this.translateService.instant('PRIMENG.DAY_NAMES'),
      dayNamesShort: this.translateService.instant('PRIMENG.DAY_NAMES_SHORT'),
      dayNamesMin: this.translateService.instant('PRIMENG.DAY_NAMES_MIN'),
      monthNames: this.translateService.instant('PRIMENG.MONTH_NAMES'),
      monthNamesShort: this.translateService.instant('PRIMENG.MONTH_NAMES_SHORT'),
      today: this.translateService.instant('PRIMENG.TODAY'),
      weekHeader: this.translateService.instant('PRIMENG.WEEK_HEADER')
    };    
    this.config.setTranslation(this.translatedCalendar);
  }

  connectPMServer() {
    const ifr = document.createElement('iframe');
    ifr.classList.add('hidden');
    document.body.appendChild(ifr);
    ifr.src = this.socialAppConnectUrl;
    const connect = () => {
      ifr.contentWindow?.postMessage({ type: 'connect' }, '*');
    };
    ifr.onload = connect;
  }

  @HostListener('window:message', ['$event'])
  receiveMessage(event: any) {
    console.warn('event', event);
    if (window.location.hostname !== 'localhost') {
      caches.keys().then(function (names) {
        for (let name of names) caches.delete(name);
      });
      if (
        event.origin === this.socialApp &&
        (event.data.type === 'authorized' || event.data.type === 'unauthorized')
      ) {
        const accessToken = event.data?.token?.accessToken;
        let user = event.data?.profile;
        const profile = user ? JSON.parse(user) : undefined;
        if (accessToken && profile) {
          this.socialAppConnectUrl = '';
          this.isLoading = false;
          this.tokenStorageService.saveToken(accessToken);
          localStorage.setItem(
            environment.USER_ROLE_KEY,
            JSON.stringify(['USER'])
          );
          const user = JSON.parse(
            localStorage.getItem(environment.USER_PROFILE) || '{}'
          );
          localStorage.setItem(
            environment.USER_PROFILE,
            JSON.stringify(profile)
          );
          localStorage.setItem(
            'auth_status',
            JSON.stringify({ isLoggedIn: true })
          );
          this.userService.setUserInfo(profile);
          if (profile?.id != user.id) {
            this.userService.getUserInfoById(profile?.id).subscribe((data) => {
              if (data) {
                this.userService.setUserInfo(data);
                localStorage.setItem(
                  environment.USER_PROFILE,
                  JSON.stringify(data)
                );
                this.userService.setLoginStatus(true);
                localStorage.setItem(
                  'auth_status',
                  JSON.stringify({ isLoggedIn: true })
                );
                this.userService.setUserProfile(data);
              }
            });
          }
          localStorage.removeItem('unauthLang');
          this.userService.setLoginStatus(true);
        } else {
          this.isLoading = false;
          localStorage.setItem('unauthLang', event.data.language);
          localStorage.setItem('currency-conversation-data', event.data.currency);
          this.translateService.setDefaultLang(event.data.language);
          this.soctripTranslationService.setLanguage(event.data.language);
          localStorage.removeItem(environment.AUTH_STATUS);
          localStorage.removeItem(environment.TOKEN_KEY);
          localStorage.removeItem(environment.USER_PROFILE);
          localStorage.removeItem(environment.ACCESS_TOKEN_EXPIRY_KEY);
          localStorage.removeItem(environment.REFRESH_TOKEN_EXPIRY_KEY);
          localStorage.removeItem(environment.REFRESH_TOKEN_KEY);
          localStorage.removeItem(environment.USER_ROLE_KEY);
          this.userService.setUserInfo(null);
          this.userService.setUserProfile(null);
          this.userService.setLoginStatus(false);
          const currencyUrlArr =  window.location.pathname.split('/')
          const isRequirePermission = currencyUrlArr
          .filter((element_url) => AUTH_GUARD_ROUTES.includes(element_url));
          if(isRequirePermission.length > 0){
            const checkInclude = isRequirePermission.includes('my-booking')
            if(checkInclude){
              if(currencyUrlArr.length !== 5 ){
                this.router.navigate(['/']);
              }
            }else{
                this.router.navigate(['/']);
            }
          }
          // window.location.href = environment.SOCIAL_APP_URL + '/login';
        }
      }
    } else {
      this.isLoading = false;
      this.userService.setUserProfile(null);
    }
  }

  login() {
    this.authService.login(this.loginForm).subscribe((res: any) => {
      this.tokenStorageService.saveToken(res.accessToken);
      this.tokenStorageService.saveRefreshToken(res.refreshToken);
      this.tokenStorageService.saveAccessTokenExpiry(
        //TODO: Replace with actual code
        // res.access_token_expiry_date
        '2023-07-17 10:14:55'
      );
      this.tokenStorageService.saveRefreshTokenExpiry(
        //TODO: Replace with actual code
        // res.refresh_token_expiry_date
        '2023-07-30 17:50:55'
      );

      //TODO: Replace with actual code
      // userID (temporary)
      const userId = '123456';
      this.userService.getUserInfo(userId).subscribe((res: any) => {
        //TODO: Replace with actual code
        this.userRole = res;
        this.userService.saveUserInfoToLocalStorage(this.userRole);
      });
      this.permissionService.userRoles.next(this.userRole);
      this.router.navigate(['/users']);
    });
  }

  logout() {
    this.tokenStorageService.clearTokenLocalStorage();
    this.userService.clearUserLocalStorage();
    this.permissionService.userRoles.next(['']);

    //TODO: Replace with actual code
    // localStorage.clear();
    this.router.navigate(['/auth/login']);
  }

  //TODO: Replace with actual code
  getUserInfo() {
    this.authService.getUserInfo().subscribe();
    //TODO: Replace with actual code
    // localStorage.clear();
    this.router.navigate(['/auth/login']);
  }

  test(a: number, b: number): number {
    return a + b;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
